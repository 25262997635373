import { createSlice } from "@reduxjs/toolkit";
import { FlashCard } from "../../../Services/api/FlashCards.js";
import Taost from "../../../Components/Toast/Toast.js";
import { toast } from "react-toastify";

const GetFlashCardsSlice = createSlice({
    name:"flashCards",
    initialState:{
        CardData:[],
        document_id:''
    },
    reducers:{
        GetData(state,action){
          state.CardData = [...state.CardData, ...action.payload];
        },
        addDocument: (state, action)=>{
            state.document_id = action.payload;
        },
        clearData:(state,action)=>{
          state.CardData = action.payload;
        }
    }
})

export const { GetData ,addDocument,clearData } = GetFlashCardsSlice.actions;
export const { reducer: FlashCardsReducer } = GetFlashCardsSlice;

const GetallCards = (FlashCardData) => async (dispatch) => {
  try {
    const response = await FlashCard.GetCards(FlashCardData);
    if (response.status === 200) {
      localStorage.setItem("FlashCards", JSON.stringify(response.data.data));
      dispatch(GetData(response.data.data.flash_cards));
      return response;
    }
  } catch (error) {
    console.log(error)
    if(error?.response?.data?.error_code === 1022){
      dispatch(GetData([]));
      return error;
    }
  }
};

const CardByLimit = (Carddata) => async (dispatch) =>{
  try {
    const response = await FlashCard.GetByLimit(Carddata);
    if(response.status === 200){
      dispatch(GetData(response.data.data.flash_cards));
    }
  } catch (error) { 
    if(error?.response?.data?.error_code === 1022){
      dispatch(GetData([]));
    }
  }
}

const GetCardsPara = (FlashCardData) => async (dispatch) => {
    try {
      const response = await FlashCard.GetCardsParameter(FlashCardData);
      if (response) {
        dispatch(GetData(response.data.data.flash_cards));
        return response;
      }
    } catch (error) {
      console.log(error);
      if(error?.response?.data?.error_code === 1022){
        return error
      }
    }
};

const GetSearchData = (FlashCardData) => async (dispatch) => {
    try {
      const response = await FlashCard.GetSearchValue(FlashCardData);
      if (response) {
        dispatch(clearData([]));
        dispatch(GetData(response.data.data.flash_cards));
        return response;
      }
    } catch (error) {
      console.log(error)
      if(error?.response?.data?.error_code === 1022){
        dispatch(GetData([]));
        return error;
      }
    }
};

const GetSearchDataPara = (FlashCardData) => async (dispatch) => {
  try {
    const response = await FlashCard.GetSearchValuePara(FlashCardData);
    if (response) {
      dispatch(clearData([]));
      dispatch(GetData(response.data.data.flash_cards));
      return response;
    }
  } catch (error) {
    if(error?.response?.data?.error_code === 1022){
      dispatch(GetData([]));
      return error;
    }
  }
};

const RatingApi = (FlashRating) =>async (dispatch) =>{
  try {
    const response = await FlashCard.PostRating(FlashRating);

  } catch (error) {
    console.log(error);
  }
}

const Editcard = (editD) => async (dispatch)=>{
  try {
    const res = await FlashCard.UpdateCard(editD);
    return res;
  } catch (error) {
    console.log(error);
    toast(<Taost message={"لا يمكنك تعديل أو حذف البطاقات لأنك لا تملك الملف."} error />);
  }
}


const AddCardData = (AddD) => async (dispatch)=>{
  try {
    const res = await FlashCard.AddCard(AddD);
    return res;
  } catch (error) {
    console.log(error);
  }
}

const deleteCard = (deleteC) => async (dispatch) =>{
  try {
    const response = await FlashCard.DeleteFlashCard(deleteC);
    return response;
  } catch (error) {
    console.log(error);
    toast(<Taost message={"لا يمكنك تعديل أو حذف البطاقات لأنك لا تملك الملف."} error />);
  }
}

const UserUpdateResponse = (updatedRes) => async ()=>{
  try {
    const res = await FlashCard.UserResponse(updatedRes);
    return res;
  } catch (error) {
    console.log(error);

  }
}



export { GetallCards , GetCardsPara ,Editcard ,deleteCard , UserUpdateResponse,GetSearchData,RatingApi,AddCardData,CardByLimit,GetSearchDataPara};
export default GetFlashCardsSlice.reducer;
